import {
  KioskUpdateIdentityDocumentDetails,
  KioskUpdateIdentityDocuments,
} from 'api/KioskApi/entries';
import { DocumentFormValues } from 'views/RegistrationCardEditPersonal/types';

import { prepareAddUpdateRemovePayload } from './prepareAddUpdateRemovePayload';

const mapSingleDocumentToUpdatePayload = (
  documentFormData: DocumentFormValues
): KioskUpdateIdentityDocumentDetails => {
  return {
    id: documentFormData.id,
    typeId: documentFormData.type,
    expiryDate: documentFormData.expiryDate,
    number: documentFormData.number,
  };
};

export const prepareDocumentsUpdatePayload = (
  formData?: DocumentFormValues[],
  initialValues?: DocumentFormValues[]
): KioskUpdateIdentityDocuments | undefined => {
  const payload = prepareAddUpdateRemovePayload(
    mapSingleDocumentToUpdatePayload,
    formData,
    initialValues
  );

  if (payload.remove) {
    delete payload.remove;
  }

  return Object.keys(payload).length ? payload : undefined;
};

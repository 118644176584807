import { Nationality, RawNationality } from '@ac/library-api';

import {
  BaseKioskConfigurationEntity,
  KioskBaseDocument,
  RawBaseKioskConfigurationEntity,
} from '../shared';

export interface RawKioskIdentityDocument extends KioskBaseDocument {
  id: string;
  type?: RawBaseKioskConfigurationEntity;
  country?: RawBaseKioskConfigurationEntity;
  issueDate?: string;
  placeOfIssue?: string;
  issuingAuthority?: string;
  countryOfBirth?: RawBaseKioskConfigurationEntity;
  dateOfBirth?: string;
  placeOfBirth?: string;
  nationality?: RawNationality;
  additionalInformation?: string;
}

export class KioskIdentityDocument {
  public id: string;
  public type?: BaseKioskConfigurationEntity;
  public country?: BaseKioskConfigurationEntity;
  public number?: string;
  public expiryDate?: string;
  public issueDate?: string;
  public placeOfIssue?: string;
  public issuingAuthority?: string;
  public countryOfBirth?: BaseKioskConfigurationEntity;
  public dateOfBirth?: string;
  public placeOfBirth?: string;
  public nationality?: Nationality;
  public additionalInformation?: string;

  constructor(raw: RawKioskIdentityDocument) {
    this.id = raw.id;
    this.type = raw.type && new BaseKioskConfigurationEntity(raw.type);
    this.country = raw.country && new BaseKioskConfigurationEntity(raw.country);
    this.number = raw.number;
    this.expiryDate = raw.expiryDate;
    this.issueDate = raw.issueDate;
    this.placeOfIssue = raw.placeOfIssue;
    this.issuingAuthority = raw.issuingAuthority;
    this.countryOfBirth =
      raw.countryOfBirth &&
      new BaseKioskConfigurationEntity(raw.countryOfBirth);
    this.dateOfBirth = raw.dateOfBirth;
    this.placeOfBirth = raw.placeOfBirth;
    this.nationality = raw.nationality && new Nationality(raw.nationality);
    this.additionalInformation = raw.additionalInformation;
  }
}

import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskIdentityDocument } from 'api/KioskApi/entries';
import { IdentityDocumentSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { DateManager } from 'utils';

import { ProfilePresentationDataElement } from './types';

interface DocumentsVisibilityConfig {
  fieldsConfiguration?: IdentityDocumentSection;
  shortDateFormat?: string;
}

export const mapDocumentsData = (
  documents?: KioskIdentityDocument[],
  settings?: DocumentsVisibilityConfig
): ProfilePresentationDataElement[] => {
  if (!documents) return [];

  const fieldsConfiguration = settings?.fieldsConfiguration;

  return documents.map((doc) => ({
    header: fieldsConfiguration?.typeField?.isVisible
      ? doc.type?.description
      : undefined,
    sectionData: [
      fieldsConfiguration?.numberField?.isVisible
        ? {
            value: doc.number,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.NUMBER'),
          }
        : undefined,
      fieldsConfiguration?.expiryDateField?.isVisible
        ? {
            value:
              doc.expiryDate &&
              DateManager.getFormattedDate(
                doc.expiryDate,
                settings?.shortDateFormat
              ),
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.EXPIRY_DATE'),
          }
        : undefined,
    ].filter(isDefined),
  }));
};
